
import {
  Component,
  Vue,
  Inject,
  Prop,
  Watch,
  Ref
} from "vue-property-decorator";

import api from "@/api";
import {
  CapitalExpenseRecordDetailDto,
  DataDictionaryDto,
  ProjectFullInfoDto,
  InternalBudgetItemAndUseRecordDto
} from "@/api/appService";
import { ElForm } from "element-ui/types/form";
import BudgetItemSelect from "@/views/finance/expenseRecord/selectBudgetItem.vue";

@Component({
  name: "CapitalExpenseRecordDetail",
  components: {
    BudgetItemSelect
  }
})
export default class CapitalExpenseRecordDetail extends Vue {
  @Ref() readonly dataForm!: ElForm;

  @Prop({ required: true })
  index!: number;

  @Prop({ required: true })
  data!: CapitalExpenseRecordDetailDto;

  @Prop({ required: true })
  projectTypeList!: DataDictionaryDto[];

  projectList: ProjectFullInfoDto[] = [];
  currentProjectTypeList: DataDictionaryDto[] = [];
  budgetItemList: InternalBudgetItemAndUseRecordDto[] = [];
  isInternal: boolean = false;
  isFirst = true;

  get title() {
    return "选择预算";
  }

  @Watch("data.projectType")
  initProjectList() {
    if(!this.isFirst){
      this.isFirst = false;
      this.data.projectId = undefined;
    }
    this.getProjectList();
    this.initBudgetItemList();
  }

  created() {
    this.getProjectTypeList();
    this.getProjectList();
  }

  getProjectTypeList() {
    api.dataDictionary
      .getDataDictionaryListByKey({ key: "ProjectType" })
      .then(res => {
        this.currentProjectTypeList = res.items!;
      });
  }

  // @Watch("show")
  // async onShowChange(value: boolean) {
  //   if (value) {
  //     const loading = this.$loading({
  //       target: ".el-dialog"
  //     });

  //     if (this.current.projectId) {
  //       await api.capitalExpenseRecord
  //         .getInternalBudgetItemAndUseRecord({
  //           projectId: this.current.projectId
  //         })
  //         .then((res: any) => {
  //           this.form = res!;
  //         })
  //         .finally(() => {
  //           setTimeout(() => {
  //             loading.close();
  //           }, 200);
  //         });
  //     } else {
  //       this.form = [];
  //       //
  //       setTimeout(() => {
  //         loading.close();
  //       }, 200);
  //     }
  //   } else {
  //     this.form = [];
  //   }
  // }


  getProjectList() {
    let pType = ''
    if (this.data.projectType) {
      pType = "," + this.data.projectType + ","
    }

    api.project
      .getAll({
        projectTypes: pType,
        isIgnorePublicShow: true,
        maxResultCount: 1000
      })
      .then(res => {
        var obj = res.items!;
        obj.push({
          id: undefined,
          displayName: "待分摊"
        });
        this.projectList = obj;
      });
    // var other = this.projectList.push({
    //   id: undefined,
    //   displayName: "待分摊"
    // });
  }

  // 编辑
  handleBudgetItemSelect() {
    (this.$refs.editForm as any).show = true;
    (this.$refs.editForm as any).current = this.data;
    //this.dataIndex = index;
  }

  setThirdItem() {
    const result = this.projectList.filter(
      item => item.id == this.data.projectId
    );
    if (result != null && result.length > 0 && result[0].isInternal == true) {
      this.isInternal = true;
      this.data.itemName = "";
      api.capitalExpenseRecord
        .getInternalBudgetItemAndUseRecord({
          projectId: this.data.projectId
        })
        .then((res: InternalBudgetItemAndUseRecordDto[]) => {
          this.budgetItemList = this.budgetItemList.concat(res);
        });
    } else {
      this.isInternal = false;
      this.data.internalProjectBudgetItemId = undefined;
    }
    this.$emit("calcTotalMoney", 0);
  }

  initBudgetItemList() {
    if (
      (this.data.projectId ?? 0) > 0 &&
      this.data.id != null &&
      this.data.internalProjectBudgetItemId != null
    ) {
      api.capitalExpenseRecord
        .getInternalBudgetItemAndUseRecord({
          projectId: this.data.projectId
        })
        .then((res: InternalBudgetItemAndUseRecordDto[]) => {
          this.budgetItemList = this.budgetItemList.concat(res);
        });
    }
  }

  handelOnSaved(row: InternalBudgetItemAndUseRecordDto) {
    // debugger
    this.$set(this.data,"internalProjectBudgetItemId",row.id)
    this.$set(this.data,"itemName",row.content)
    // this.data.internalProjectBudgetItemId = row.id;
    // this.data.itemName = row.content;
    //this.$emit("update:data", this.data);
    // var exsistRecord = this.budgetItemList.filter(function(item) {
    //   return item.id == row.id;
    // });
    // if (exsistRecord == null || exsistRecord.length == 0) {
    //   this.budgetItemList.push(row);
    // }
    // this.form.details![this.dataIndex].internalProjectBudgetItemId = row.id;
  }

  testOver(e: number) {
    let currentRecordTotal: number = 0;
    let budgetItem = this.budgetItemList.filter(
      item => item.id == this.data.internalProjectBudgetItemId
    );
    if (budgetItem != null && budgetItem.length > 0) {
      var tt = budgetItem[0].actualMoney ?? 0;
      var ss = this.data!.money! ?? 0;
      var kk = budgetItem[0].budgetQuotation! ?? 0;
      var ts = tt + ss;
      var b = ts > kk;
      if (b) {
        this.data.isOver = true;
      } else {
        this.data.isOver = false;
      }
    }
    this.$emit("calcTotalMoney", e);
  }

  deleteRow() {
    this.$emit("onDelete", this.index);
  }

  detailRule = {
    // projectType: [
    //   {
    //     required: true,
    //     message: "请选择开始日期",
    //     trigger: "blur"
    //   },
    // ],
    // endDate: [
    //   {
    //     required: true,
    //     message: "请选择结束日期",
    //     trigger: "change"
    //   },
    // ],
    // partnerOrgId: [
    //   {
    //     required: true,
    //     message: "请选择合作机构",
    //     trigger: "change"
    //   },
    // ],
  };
}
