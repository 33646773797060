
import {Component, Ref, Vue, Watch} from "vue-property-decorator";
import {
  CapitalExpenseRecordCreateOrUpdateDto,
  CapitalExpenseRecordDetailCreateOrUpdateDto,
  CapitalExpenseRecordDto,
  DataDictionaryDto,
  OrganizationUnitDto,
  InternalBudgetItemAndUseRecordDto,
  CapitalExpenseRecordDetailDto,
  ProjectDto
} from "@/api/appService";
import api from "@/api";
import {ElForm} from "element-ui/types/form";
import ProjectSelect from "@/components/ProjectSelect/index.vue";
import RecordDetail from "@/views/finance/expenseRecord/recordDetail.vue";

@Component({
  name: "CapitalExpenseRecordEdit",
  components: {
    ProjectSelect,
    RecordDetail
  }
})
export default class CapitalExpenseRecordEdit extends Vue {
  @Ref() readonly dataForm!: ElForm;
  dataIndex: number = 0;
  dataId!: number;
  detailList: CapitalExpenseRecordDetailCreateOrUpdateDto[] = [
    {
      /**  */
      sequence: undefined,

      /** 项目类型 */
      projectType: undefined,

      /** 项目类型名称 */
      projectTypeName: undefined,

      /** 项目Id（-1为待分摊） */
      projectId: undefined,

      /** 内部项目预算子项目内容 */
      internalProjectBudgetItemId: undefined,

      /** 三级科目 */
      itemName: undefined,

      /** 摘要 */
      summary: undefined,

      /** 本次支付金额（元） */
      money: undefined,

      /** 备注 */
      remark: undefined,

      /** 是否超出预算(该项目该三级科目已支付金额加本次支付金额是否超出预算金额) */
      isOver: undefined,

      /**  */
      id: undefined
    }
  ];
  defaultData: CapitalExpenseRecordCreateOrUpdateDto = {
    /** 收款单位 */
    payeeName: undefined,

    /** 开户银行 */
    payeeBankName: undefined,

    /** 开户账号 */
    payeeBankAccount: undefined,

    /**  */
    payType: undefined,

    /** 付款账号（数据字典，多选一） */
    payBankAccount: undefined,

    /** 合同编号 */
    contractNo: undefined,

    /** 合同总金额（元） */
    contractTotalMoney: 0,

    /** 累计已支付金额（元） */
    paidTotalMoney: 0,

    /** 本次支付合计金额（元） */
    totalMoney: 0,

    /** 剩余待分摊总金额（元） */
    nonApportionTotalMoney: 0,

    /**  */
    details: this.detailList,

    /**  */
    auditFlowId: undefined,

    /**  */
    id: 0
  };
  projectType = "";
  //projectList = [];

  form: CapitalExpenseRecordCreateOrUpdateDto = {...this.defaultData};
  //
  isNewPayeeName: boolean = false;
  isNewContractNo: boolean = false;
  //
  recordList: any[] = [];
  unitList: any[] = [];
  contractNoList: any[] = [];
  private payeeNameSelectList: any = [
    {
      label: "历史记录",
      options: this.recordList
    },
    {
      label: "合作方",
      options: this.unitList
    }
  ];
  //
  payTypeList = [
    {
      value: "Cash",
      label: "现金"
    },
    {
      value: "Transfer",
      label: "转账"
    }
  ];
  //
  payBankAccountList: DataDictionaryDto[] = [];
  projectTypeList: DataDictionaryDto[] = [];

  // 添加一行
  private addRow(r: number) {
    let obj = {
      id: undefined,

      sequence: undefined,

      place: undefined,

      /**  */
      startDate: undefined,

      /**  */
      endDate: undefined,

      /**  */
      summary: undefined,

      /**  */
      partnerOrgId: undefined,

      /**  */
      needSupport: undefined,

      /** 摘要 */
      remark: undefined
    };
    this.form.details = [...this.form.details!, obj];
  }

  setBankInfo(row: string) {
    //1.优先从历史记录查找
    const resultArray: any[] = [];
    this.recordList.map(item => {
      if (item.value == row) {
        resultArray.push(item);
      }
    });
    //2.合作伙伴中查找
    this.unitList.map(item => {
      if (item.value == row) {
        resultArray.push(item);
      }
    });
    //3.默认取第一个
    if (resultArray != null && resultArray.length > 0) {
      this.form.payeeBankName = resultArray[0].payeeBankName;
      this.form.payeeBankAccount = resultArray[0].payeeBankAccount;
    } else {
      this.form.payeeBankAccount = "";
      this.form.payeeBankName = "";
    }
  }

  getContractNoInfo() {
    api.capitalExpenseRecord
      .getContractNoInformation({contractNo: this.form.contractNo})
      .then(res => {
        this.form.contractTotalMoney = res.contractTotalMoney ?? 0;
        this.form.paidTotalMoney = res.paidTotalMoney ?? 0;
      });
  }

  switchInput() {
    if (this.isNewPayeeName) {
      this.isNewPayeeName = false;
    } else {
      this.isNewPayeeName = true;
    }
    this.form.payeeBankAccount = "";
    this.form.payeeBankName = "";
    this.form.payeeName = "";
  }

  switchContractNo() {
    if (this.isNewContractNo) {
      this.isNewContractNo = false;
    } else {
      this.isNewContractNo = true;
    }
    this.form.contractNo = "";
    this.form.contractTotalMoney = 0;
    this.form.paidTotalMoney = 0;
  }

  // 删除整行
  deleteRow(index: number) {
    this.form.details!.splice(index, 1);
  }

  created() {
    this.initDefaultData();
    this.dataId = Number(this.$route.params.id);
    if (this.dataId) {
      this.fetchDetail();
    }
  }

  qc(oldArray: any[]) {
    const newArray: any[] = [];
    oldArray.forEach(element => {
      //
      var exesistArray = newArray.filter(function (item) {
        return item.value == element.value;
      });
      if (exesistArray == null || exesistArray.length == 0) {
        newArray.push(element);
      }

      newArray.forEach(item => {
        if (element.value == item.value) {
          item = element;
        }
      });
    });
    return newArray;
  }

  initDefaultData() {
    //待优化
    api.capitalExpenseRecord.getAll().then(res => {
      const record = res.items!;
      res.items!.map((item: CapitalExpenseRecordDto) => {
        this.recordList.push({
          value: item.payeeName,
          label: item.payeeName,
          payeeBankName: item.payeeBankName,
          payeeBankAccount: item.payeeBankAccount
        });
        this.contractNoList.push({
          value: item.contractNo,
          label: item.contractNo
        });
      });
      //去重操作
      this.payeeNameSelectList[0].options = this.qc(this.recordList);
      //this.recordList=this.qc(this.recordList);
      this.contractNoList = this.qc(this.contractNoList);
    });
    //待优化
    api.organizationUnit.getAll().then(res => {
      const record = res.items!;
      res.items!.map((item: OrganizationUnitDto) => {
        this.unitList.push({
          value: item.displayName,
          label: item.displayName,
          payeeBankName: item.bankName,
          payeeBankAccount: item.bankAccount
        });
      });
      //去重
      this.payeeNameSelectList[1].options = this.qc(this.unitList);
      //this.unitList=this.qc(this.unitList);
    });

    //付款账号
    api.dataDictionary
      .getDataDictionaryListByKey({key: "PayBankAccount"})
      .then(res => {
        this.payBankAccountList = res.items!;
      });
    // //项目类别
    // api.dataDictionary
    //   .getDataDictionaryListByKey({ key: "ProjectType" })
    //   .then(res => {
    //     this.projectTypeList = res.items!;
    //   });
  }

  // 获取详情
  fetchDetail() {
    api.capitalExpenseRecord.get({id: this.dataId}).then(res => {
      this.form = {...res};
      this.form.details!.forEach(element => {
        if (element.projectId == null) {
          element.projectId = undefined;
        }
      });
    });
  }

  calcTotalMoney(e: number) {
    if (this.form.details && this.form.details.length > 0) {
      var num = 0;
      var nonApportion = 0;
      this.form.details!.map(item => {
        num += Number(item.money);
        if (item.projectId == undefined) {
          //待分摊
          nonApportion += Number(item.money);
        }
      });
      this.form.totalMoney = num;
      this.form.nonApportionTotalMoney = nonApportion;
    }
  }

  cancel() {
    this.$router.go(-1);
  }

  async save() {
    (this.$refs.dataForm as any).validate(async (valid: boolean) => {
      if (valid) {
        if (this.dataId) {
          await api.capitalExpenseRecord.update({
            body: {...this.form}
          }).then(() => {
            this.$message.success("更新成功");
            this.$router.go(-1);
          });
        } else {
          await api.capitalExpenseRecord.create({
            body: {...this.form}
          }).then(() => {
            this.$message.success("新建成功");
            this.$router.go(-1);
          });
        }
      } else {
        this.$message({
          type: "error",
          message: "请检查表单"
        });
      }
    });
  }

  roleRule = {
    payeeName: [
      {
        required: true,
        message: "收款单位不能为空",
        trigger: "blur"
      }
    ]
  };
  detailRule = {
    //   startDate: [
    //     {
    //       required: true,
    //       message: "请选择开始日期",
    //       trigger: "change"
    //     },
    //   ],
    //   endDate: [
    //     {
    //       required: true,
    //       message: "请选择结束日期",
    //       trigger: "change"
    //     },
    //   ],
    //   partnerOrgId: [
    //     {
    //       required: true,
    //       message: "请选择合作机构",
    //       trigger: "change"
    //     },
    //   ],
  };
}
