
import {
  Component,
  Vue,
  Inject,
  Prop,
  Watch,
  Ref,
} from "vue-property-decorator";

import api from "@/api";
import { InternalBudgetItemAndUseRecordDto } from "@/api/appService";
import { ElForm } from "element-ui/types/form";

@Component({
  name: "SelectCapitalExpenseRecordDetail",
  filters: {
    formatEnum(category: string, list: any[]) {
      let result = "";
      if (list && list.length > 0) {
        list.map((item) => {
          if (item.value == category) {
            result = item.text;
            return;
          }
        });
      }
      return result;
    },
  },
})
export default class SelectCapitalExpenseRecordDetail extends Vue {
  @Ref() readonly dataForm!: ElForm;

  @Prop({ required: false })
  onlyCheck!: number;

  // @Prop({ required: true })
  // dataId!: number;

  defaultData: InternalBudgetItemAndUseRecordDto = {
    /**  */
    projectId: undefined,

    /** 序列(显示顺序） */
    sequence: undefined,

    /**  */
    projectBudgetCategory: undefined,

    /**  */
    projectBudgetType: undefined,

    /** 子项目内容（三级科目） */
    itemName: undefined,

    /** 具体内容 */
    content: undefined,

    /** 单价（元） */
    unitPrice: undefined,

    /** 数量 */
    number: undefined,

    /** 单位 */
    unit: undefined,

    /** 预算报价 */
    budgetQuotation: undefined,

    /**  */
    projectBudgetCapitalSource: undefined,

    /**  */
    actualMoney: undefined,

    /**  */
    //id: undefined
  };

  show = false;
  form: InternalBudgetItemAndUseRecordDto[] = [];
  current: InternalBudgetItemAndUseRecordDto = { ...this.defaultData };
  selectedData: InternalBudgetItemAndUseRecordDto = { ...this.defaultData };
  enumList1: any = [];
  enumList2: any = [];

  get title() {
    return this.onlyCheck ? "实际开支" : "选择预算";
  }

  fetchEnum() {
    api.enumService
      .getValues({ typeName: "ProjectBudgetCategory" })
      .then((res) => {
        this.enumList1 = res;
      });
    api.enumService.getValues({ typeName: "ProjectBudgetType" }).then((res) => {
      this.enumList2 = res;
    });
  }

  @Watch("show")
  async onShowChange(value: boolean) {
    this.fetchEnum();
    if (value) {
      const loading = this.$loading({
        target: ".el-dialog",
      });

      if (this.current.projectId) {
        await api.capitalExpenseRecord
          .getInternalBudgetItemAndUseRecord({
            projectId: this.current.projectId,
          })
          .then((res: any) => {
            this.form = res!;
          })
          .finally(() => {
            setTimeout(() => {
              loading.close();
            }, 200);
          });
      } else {
        this.form = [];
        //
        setTimeout(() => {
          loading.close();
        }, 200);
      }
    } else {
      this.form = [];
    }
  }

  handleCurrentChange(val: InternalBudgetItemAndUseRecordDto) {
    this.selectedData = val;
  }

  async save() {
    this.show = false;
    //this.$message.success("更新成功");
    this.$emit("onSave", this.selectedData);
  }

  cancel() {
    this.show = false;
  }
}
